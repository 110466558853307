import * as React from 'react'

import { renderers } from '@markdoc/markdoc'
import type { HeadersFunction, LoaderFunctionArgs, MetaFunction } from '@remix-run/node'
import { json } from '@remix-run/node'
import { Link, useLoaderData } from '@remix-run/react'

import Callout from '@/components/content/Callout'
import Vimeo from '@/components/Vimeo'
import YouTube from '@/components/YouTube'
import { cacheControl } from '@/http.server'
import { findDoc } from '@/models/doc.server'
import { pageHelper } from '@/request'
import { formatLong } from '@/utils/date'

type LoaderData = typeof loader

export function loader({ params, request }: LoaderFunctionArgs) {
  const doc = findDoc(`${params['*']}`)

  if (!doc) {
    throw new Response('Not Found', { status: 404 })
  }

  const { root } = pageHelper(request)

  return json(
    {
      doc: doc,
      root: root
    },
    {
      headers: {
        'Cache-Control': cacheControl
      }
    }
  )
}

export const headers: HeadersFunction = ({ parentHeaders }) => ({
  'Cache-Control': parentHeaders.get('Cache-Control') ?? ''
})

export const meta: MetaFunction<LoaderData> = ({ data }) => {
  if (!data) return []

  const {
    doc: { meta },
    root
  } = data

  const title = `${meta.title} — PlanetScale`
  const image = `${root}${meta.image}`

  return [
    { title },
    { name: 'description', content: meta.description },
    { name: 'robots', content: meta.robots },
    { property: 'og:url', content: meta.canonical },
    { property: 'og:type', content: 'website' },
    { property: 'og:title', content: title },
    { property: 'og:image', content: image },
    { property: 'og:description', content: meta.description },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:site', content: '@PlanetScale' },
    { property: 'twitter:creator', content: '@PlanetScale' },
    { property: 'twitter:url', content: meta.canonical },
    { property: 'twitter:title', content: title },
    { property: 'twitter:description', content: meta.description },
    { property: 'twitter:image', content: image },
    { tagName: 'link', rel: 'canonical', href: meta.canonical }
  ]
}

export default function Doc() {
  const { doc } = useLoaderData<LoaderData>()

  return (
    <>
      <h1>{doc.title}</h1>

      <p className='text-secondary'>
        <time dateTime={doc.createdAt}>{formatLong(doc.createdAt)}</time>
        {doc.updatedAt && (
          <>
            {' '}
            (Updated <time dateTime={doc.updatedAt}>{formatLong(doc.updatedAt)}</time>)
          </>
        )}
      </p>

      {renderers.react(doc.body, React, {
        components: {
          Callout,
          Vimeo,
          YouTube
        }
      })}

      <h2 id='need-help'>
        <Link to='#need-help'>Need help?</Link>
      </h2>

      <p>
        Get help from{' '}
        <a href='https://support.planetscale.com' target='_blank' rel='noreferrer'>
          the PlanetScale Support team
        </a>
        , or join our&nbsp;
        <a href='https://github.com/planetscale/discussion/discussions' target='_blank' rel='noreferrer'>
          GitHub discussion board
        </a>{' '}
        to see how others are using PlanetScale.
      </p>
    </>
  )
}
